import { render, staticRenderFns } from "./successful-overlay.vue?vue&type=template&id=4b34a244&scoped=true&"
var script = {}
import style0 from "./successful-overlay.vue?vue&type=style&index=0&id=4b34a244&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b34a244",
  null
  
)

export default component.exports